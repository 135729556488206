.template__wrap {
    .approved--opacity{
        opacity: 0.4;
        cursor: auto;
    }
    .disabled--btn{
        opacity: 0.4;
    }

    .approver--tooltip {
        display: grid;
        background-color: #FFFFFF;
        border: 1px solid #D4D4D4;
        align-items: start;
        text-align: left;
        opacity: 1;
        z-index: 5;
        color: black;
        width: 475px;
        height: 192.5px;
        padding: 20px;

        .tooltip-btn-div {
            margin-top: 40px;
        }

        .approver-input {
            display: block;
            margin-top: 19px;
            margin-bottom: 19px;
            height: 48px;
            align-items: center;
            border: 0.5px solid var(--Border-light, #B1B1B1);
            background: inherit;
        }
    }

    .approver-mail-btn {
        height: 40px;
        padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-s, 8px);
        float: inline-end;

        a {
            color: #FFF !important;
            cursor: pointer;
        }

        span{
            color: #FFF !important;
        }
    }
    
    .template__editwrap {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
    }

    .edittemplate {
        padding: 24px;
        background: var(--Colour-Neutral-200, #F1EEEA);
        border-radius: 8px;
    }

    .shareApprove__editTtemplate {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        padding: 24px 0px 16px 0px;
        opacity: 0.4;
    }

    .shareApprove__template {
        display: flex;
        align-items: center;
        gap: 8px;

        .share__template {
            button {
                border: 1px solid var(--Fills-brand-medium, #197A56);
                height: 40px;
                color: var(--Fills-brand-medium, #197A56);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                background-color: #FFF;
            }
        }

        .approve__template {
            button {
                height: 40px;
                padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
                gap: var(--Spacing-s, 8px);
                flex-shrink: 0;
                background: var(--Fills-brand-medium, #197A56);
                border-color: #197A56;
                color: var(--Text-light, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .editSave__templateWrap {
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }

    .template--data {
        border-radius: 8px;
        background: var(--Colour-Neutral-200, #F1EEEA);
        padding: 24px;

        .template--subText{
            p{
                margin-bottom: 0px !important;
            }
        }
        .template--colon {
            color: var(--Text-dark, #323232);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 8px;
        }

        .template--text {
            width: 70%;
            text-align: center;
            display: block;
            margin: auto;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .dx-htmleditor {
            border: none;
        }
    }

    .template--dataedit {
        border-radius: 8px;
        border: var(--Spacing-none, 0.5px) solid var(--Border-dark, #323232);
        background: var(--Fills-light, #FFF);
        padding: 24px;

        .dx-htmleditor {
            border: none;
        }
    }

    .edit--template {
        margin-top: 24px;
        margin-bottom: 16px;
        display: inline-flex;
        height: 40px;
        padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-s, 8px);
        flex-shrink: 0;
        border: 1px solid var(--Alerts-warning-dark, #BD8F1A);
        min-width: 155px;
        color: var(--Alerts-warning-dark, #BD8F1A);
        background-color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .savecancel__btn {
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        background: var(--Colour-Neutral-200, #F1EEEA);

        .save--template {
            display: flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            background: var(--Fills-brand-medium, #197A56);
            color: var(--Text-light, #FFF);
            border: 1px solid var(--Alerts-warning-dark, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .save--opacity{
            opacity: 0.4;
        }
        .cancel--template {
            border: 1px solid var(--Alerts-success-dark, #197A56);
            display: flex;
            height: 40px;
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-s, 8px);
            color: var(--Alerts-success-dark, #197A56);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .approvedBy__On {
        display: flex;
        align-items: flex-start;
        gap: 48px;
        margin-top: 16px;

        .approved--by {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .approved--byText {
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }
        }

        .approved--on {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .approved--onText {
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }

            .approved--ondate {
                color: var(--Text-dark, #323232);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}