.requestMembers__wrap {
    display: flex;
    // padding: 0px 10px;
    margin-top: 8px;
    align-items: flex-start;

    .user__group {
        display: flex;
        align-items: center;
        width: 100%;
        div{
            display: flex;
        align-items: center;
        }
    }

    .imagesMarginLeft {
        margin-left: -15px;
    }

    .imagesMarginLeftImg {
        margin-left: 0px;
    }

    .user--count {
        background: #323232;
        border: var(--Spacing-none, 1px) solid #FFF;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        margin-left: -15px;
        z-index: 99;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .noResults--found {
        position: relative;
        width: 100%;

        .no--results {
            color: #7E6011;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-left: 4px;
            position: absolute;
            top: 2px;
            width: 100%;
            text-align: left;
        }

        img {
            width: 44.632px;
            height: 48px;
            flex-shrink: 0;
            opacity: 0.8;
        }
    }
}

.req--img{
    height: 48px;
    width: 48px;
}